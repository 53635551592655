import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import appData from './appData'
import settings from './settings'
import library from './library'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		appData,
		library,
	},
	state: {
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		uploadDialog: false,
		folders: [],
		files: {},
		currentFolder: '/',
		theApp: {},
		pageButtons: [],
		loading: false,
		crumbs: [],
		sidebarCollapse: false,
		fileStructure: [],
		savingAsDraft: false
	},
	mutations: {
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		SET_SAVING_AS_DRAFT(state, data = true) {
			state.savingAsDraft = data;
		},
		SET_CRUMBS: (state, array) => state.crumbs = array,
		CLEAR_CRUMBS: (state) => state.crumbs = [],
		SIDEBAR_COLLAPSE: (state, is = null) => is !== null ? state.sidebarCollapse = Boolean(is) : state.sidebarCollapse = !state.sidebarCollapse,
		LOAD: (state, is = null) => is !== null ? state.loading = Boolean(is) : state.loading = !state.loading,
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons
		},
		SET_APP(state, data) {
			state.theApp = data
		},
		cancelUpload: (state) => {
			state.uploadDialog = false
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true
			state.folders = folders
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},

	},
	getters: {
		hasReservationAccess(state) {
			return state?.instance?.package?.options?.access?.reservation || false;
		},
	},
	actions: {



	},
})
