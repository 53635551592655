<template>
	<div v-if="pdfPreview.show" class="page_preview hide-scrollbar" style="overflow-y:scroll"
		:class="pdfPreview.show ? pdfPreview.confirm ? 'confirm show' : 'show' : ''">
		<a-icon type="close" class="close_button cursor-pointer text-white text-xl"
			@click="$store.commit('CLOSE_PREVIEW')" />
		<iframe v-if="pdfPreview.url" :src="pdfPreview.url" id="iframe" loading="lazy"></iframe>
		<iframe v-else-if="manualFile" id="fileIframe" loading="lazy"></iframe>
		<div class="page-preview-action dF jSB aC px-8 w-full" style="margin-top: auto;">
			<a-button size="large" @click="$store.commit('CLOSE_PREVIEW')">Back</a-button>
			<a-alert type="info" show-icon class="mx-3">
				<template #message>
					<div>
						Please ensure your document is under <strong>25 MB</strong> and no more than <strong>2,000
						pages</strong>;
					</div>
					<div>
						otherwise file will fail to send due to size restrictions.
						<a class="link"
							href="https://support.docusign.com/s/document-item?language=en_US&bundleId=gbo1643332197980&topicId=akk1578456465691.html&_LANG=enus" target="_blank">
							Check here for more details.
						</a>
					</div>
				</template>
			</a-alert>
			<a-button type="primary" size="large" @click="$store.commit('PAGE_PREVIEW_CALLBACK')">
				{{ buttonText }}
			</a-button>
		</div>
	</div>
</template>

<script>
	export default {
		computed: {
			pdfPreview() {
				return this.$store.state.appData.pdfPreview
			},

			buttonText() {
				if (this.pdfPreview.buttonText) return this.pdfPreview.buttonText
				if (this.pdfPreview.save && this.pdfPreview.confirm) {
					return 'SAVE DOCUMENT'
				}
				return this.pdfPreview.isManual ? 'CREATE TRANSACTION' : 'SEND CONTRACT'
			},

			manualFile() {
				return this.pdfPreview.file
			}
		},
		watch: {
			manualFile(val) {
				if (val) {
					this.$nextTick(() => {
						const frameElement = document.getElementById("fileIframe");
						frameElement.src = window.URL.createObjectURL(val);
					})
				}
			}
		}
	}
</script>

<style lang="scss">
	.page_preview {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .6);
		display: flex;
		z-index: 1005;
		opacity: 0;
		pointer-events: none;
		transition: opacity .3s ease-out;
		justify-content: center;
		align-items: center;

		&.show {
			opacity: 1;
			pointer-events: all;
		}

		.close_button {
			position: absolute;
			top: 20px;
			right: 20px;
		}

		canvas,
		iframe {
			width: 100%;
			flex: 1;
		}

		&:not(.confirm) {
			.page-preview-action {
				display: none;
			}

			canvas,
			iframe {

				width: 80%;
				height: 90%;
			}
		}

		&.confirm {
			flex-direction: column;
			background: white;

			.close_button {
				display: none;
			}

			iframe,
			canvas {
				box-shadow: 0 2px 30px rgba(100, 100, 100, 0.3);
			}
		}
	}

	.page-preview-action {
		height: 100px;
	}
</style>
