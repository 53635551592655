<template>
	<LeftNavBar class="f1" :items="$route.meta.leftNav ? navItems : []" @opened="navOpened" :title="selectedAppInitial"
		:selectedValue="selectedNav">
	</LeftNavBar>
</template>

<script>
	import LeftNavBar from "bh-mod/components/common/LeftNavBar";

	export default {
		components: { LeftNavBar },
		name: "menu-left",
		data() {
			return {};
		},
		computed: {
			selectedAppInitial() {
				return (
					(this.$store.state.theApp &&
						this.$store.state.theApp.media &&
						this.$store.state.theApp.media.initial) ||
					"TM"
				);
			},

			instance() {
				return this.$store.state.instance
			},

			navItems() {
				const items = [
					{
						label: "All Transactions",
						value: "all",
						iconUrl: require("@/assets/sideIcons/all.svg"),
					},
					{
						label: "Pending",
						value: "created",
						iconUrl: require("@/assets/sideIcons/created.svg"),
					},
					{
						label: "Completed",
						value: "completed",
						iconUrl: require("@/assets/sideIcons/completed.svg"),
					},
					{
						label: "Draft",
						value: "draft",
						iconUrl: require("@/assets/sideIcons/draft.svg"),
					},
					{
						label: "Voided",
						value: "void",
						iconUrl: require("@/assets/sideIcons/void.svg"),
					},
					{
						label: "Archived",
						value: "archived",
						iconUrl: require("@/assets/sideIcons/archived.svg"),
					},
					{
						label: "Reservations",
						value: "reservations",
						iconUrl: require("@/assets/sideIcons/reservation.svg")
					},
					{
						divider: true,
					},
					{
						label: "Settings",
						value: "settings",
						iconUrl: require("@/assets/sideIcons/settings.svg")
					},
				];

				if (!this.$store.getters.isReservationActive) {
					items.splice(6, 1)
				}

				return items
			},
			selectedNav() {
				if (this.$route.path == "/settings") return "settings";
				if (this.$route.path.toLowerCase().includes("reservation")) return "reservations";
				return this.transactionType.value.toLowerCase();
			},
			transactionType() {
				return this.$store.state.appData.transactionType;
			},
		},
		methods: {
			navOpened(e) {
				if (e.value == "settings") {
					if (this.$route.path !== "/settings") {
						this.$router.push("/settings");
						return;
					}
				}
				if (e.value == "reservations") {
					if (this.$route.path !== "/reservations") {
						this.$router.push("/reservations");
						return;
					}
				}
				if (this.$route.path !== "/") {
					this.$router.push("/");
				}

				let navItem = this.navItems.find((x) => x.value === e.value);
				if (!navItem) {
					return;
				}
				if (navItem.value === this.transactionType.value) return;
				this.$store.commit("TRANSACTION_TYPE", navItem);
				return this.getTransactions();
			},

			async getTransactions() {
				this.$nprogress.start();
				this.$store.commit('SET_TRANSACTIONS_PAGE')
				try {
					await this.$store.dispatch("FETCH_TRANSACTIONS");
				} catch (error) {
					console.error(error);
				}
				this.$nprogress.done();
			}
		},
	};
</script>
